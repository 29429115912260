import { motion, useAnimationControls } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import TinderCard from 'react-tinder-card';

const Header = () => {
  const [ logo_height, setLogo_height ] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if(ref.current) {
      setLogo_height(ref.current.scrollHeight);
    }
  }, [ref]);

  return (
    <div className='w-full h-fit flex justify-center gap-3 pt-4 pb-4'>
      <img src='https://www.vectorico.com/wp-content/uploads/2018/02/Tinder-Logo.png' className='w-auto' style={{height: logo_height + "px"}}/>
      <p ref={ref} className='notosan-bold text-2xl sm:text-4xl text-tinder-black'>Potassium</p>
      <p className='notosan-bold text-2xl sm:text-4xl text-tinder-pink'>Tinder</p>
    </div>
  )
}

const Footer = ({score, maxScore, handleLike, handleDislike, handleSuperlike, handle_look_score}) => {
  return (
    <div className='w-full h-fit pt-2 xl:pt-6 pb-4'>
      <p className='notosan-med text-tinder-pink text-center text-lg'>ใบที่ {score} / {maxScore}</p>

      <div className='w-full h-fit flex justify-center my-0 2xl:my-3'>
        <button className='notosan-reg w-fit h-fit px-4 py-2 border border-gray-300 rounded-md cursor-pointer hover:bg-tinder-pink hover:text-white hover:border-tinder-pink' onClick={handle_look_score}>ดูคะแนน</button>
      </div>

      <div className='w-full h-fit flex justify-center gap-5 mt-2 2xl:mt-6'>
        <div className='w-14 h-14 rounded-full flex justify-center items-center bg-white drop-shadow-lg cursor-pointer' onClick={handleDislike}>
          <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKy0euNk9LPQ2AIb5kXoOvIW-4_LUirqvQDQ&s' className='w-8 h-8'/>
        </div>

        <div className='w-14 h-14 rounded-full flex justify-center items-center bg-white drop-shadow-lg cursor-pointer' onClick={handleSuperlike}>
          <box-icon name='star' type='solid' size="md" color="#06B6D4"></box-icon>
        </div>

        <div className='w-14 h-14 rounded-full flex justify-center items-center bg-white drop-shadow-lg cursor-pointer' onClick={handleLike}>
          <box-icon name='heart' type='solid' size="md" color="#86EFAC"></box-icon>
        </div>
      </div>
    </div>
  )
}

const Modal_show_record = ({isShow, records, maxScore, handle_close}) => {
  const [ score, setScore ] = useState(0);

  useEffect(() => {
    let result = 0;

    records.forEach(x => {
      result += parseInt(x.score);
    })

    setScore(result);
  }, [records]);

  return (
    <div className={'absolute top-0 left-0 w-screen h-screen bg-black/50 justify-center items-center z-40 ' + (isShow ? 'flex' : 'hidden')}>
      <div className='w-5/6 h-5/6 bg-white rounded-md px-20 py-14 flex flex-col justify-between'>
        <div className='w-full h-fit flex justify-end'>
          <div className='w-fit h-fit cursor-pointer' onClick={handle_close}>
            <box-icon name='x'></box-icon>
          </div>
        </div>

        <div className='grow overflow-y-scroll'>
          {
            records.map((x, i) => {
              return (
                <div key={i} className={'w-full h-fit border px-4 py-2 rounded-md flex justify-between my-2 ' + ((x.score === 1) ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300')}>
                  <p className={'notosan-reg ' + ((x.score === 1) ? 'text-green-800' : 'text-red-800')}>{i + 1}. {x.name} ({x.potassium} mg/100g)</p>
                  
                  {
                    (x.score === 1) ?
                      <p className={'notosan-reg ' + ((x.score === 1) ? 'text-green-800' : 'text-red-800')}>✓</p>
                    :
                      <p className={'notosan-reg ' + ((x.score === 1) ? 'text-green-800' : 'text-red-800')}>X</p>
                  }
                </div>
              )
            })
          }
        </div>

        <div className='w-full h-fit flex justify-center py-4'>
          <p className='notosan-med text-web-black text-xl'>คะแนนรวม {score} / {maxScore} คะแนน</p>
        </div>
      </div>
    </div>
  )
}

const Potassium_Tinder = () => {
  const default_items = [
    {
      name: 'กล้วยน้ำว้า',
      image: 'https://static.trueplookpanya.com/tppy/member/m_557500_560000/559384/cms/images/shutterstock_768954520.jpg',
      description: "ผิวเรียบ หวาน และมีเสน่ห์เสมอ พร้อมเป็นคนที่เติมพลังงานทั้งวันให้กับคุณ ถ้าชอบก็ปัดขวาได้เลย!",
      potassium: 241,
    },
    {
      name: 'แก้วมังกร',
      image: 'https://cdn.pixabay.com/photo/2017/04/27/02/40/dragonfruit-2264207_1280.jpg',
      description: "ถึงภายนอกผมจะลุคหล่อเท่ แต่จริงๆ แล้วข้างในนุ่มนวลและอ่อนหวาน แล้วคุณจะติดใจ!",
      potassium: 271,
    },
    {
      name: 'ขนุน',
      image: 'https://loopnewslive.blob.core.windows.net/liveimage/sites/default/files/2020-09/ROHEsFwi2w.jpg',
      description: "เป็นคนที่ชอบความพิเศษและพร้อมจะลองสิ่งใหม่ๆ ถ้าคุณพร้อมที่จะค้นพบความอร่อยที่ไม่ซ้ำใคร ปัดขวาได้เลย!",
      potassium: 386,
    },
    {
      name: 'เงาะ',
      image: 'https://static.thairath.co.th/media/dFQROr7oWzulq5Fa5BIXriANH5iKhWaCd8UP2W5f6AHtoR74KoZbtF2RHC2ujQplaxy.jpg',
      description: "หนุ่มหล่อขนฟู ที่พร้อมจะทำให้เธอใจฟูถ้าได้คุย",
      potassium: 146,
    },
    {
      name: 'ชมพู่',
      image: 'https://inwfile.com/s-cx/ly0f8l.jpg',
      description: "ชื่อว่า ชมพู่ แต่จริงๆ ชอบชมเธอ!! 😳",
      potassium: 109,
    },
    {
      name: 'แตงโม',
      image: 'https://medthai.com/wp-content/uploads/2013/07/%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B9%81%E0%B8%95%E0%B8%87%E0%B9%82%E0%B8%A1.jpg',
      description: "หวาน ฉ่ำ และพร้อมสนุกทุกเมื่อ! ฉันคือดาวเด่นของทุกปาร์ตี้หน้าร้อนและรับประกันว่าจะทำให้คุณรู้สึกสดชื่น 😉🍉",
      potassium: 107,
    },
    {
      name: 'ทุเรียนหมอนทอง',
      image: 'https://www.technologychaoban.com/wp-content/uploads/2024/05/หมอนทองเขาบรรทัด1.jpg',
      description: "แก่ ใจดี สปอร์ต จันทบุรี",
      potassium: 389,
    },
    {
      name: 'น้อยหน่า',
      image: 'https://f.ptcdn.info/870/073/000/quwfopqr742V7i1rIhv-o.jpg',
      description: "ภายนอกอาจดูขรุขระ แต่ข้างในนุ่มนวลหวานฉ่ำ ถ้าชอบคนที่มีเสน่ห์เฉพาะตัว ปัดขวาเลย! 🥰",
      potassium: 219,
    },
    {
      name: 'ฝรั่ง',
      image: 'https://www.exoticexpress.co.uk/cdn/shop/products/image_3cbdb0f3-a22d-4d80-8b68-143ba729e5b2.jpg?v=1681718977',
      description: "จุดแข็ง คือ วิตามินซีเยอะ จุดอ่อน คือ ยังโสดงับ",
      potassium: 175,
    },
    {
      name: 'มะม่วงน้ำดอกไม้ (สุก)',
      image: 'https://www.paolohospital.com/Resource/Image/Article/shutterstock_724213996.jpg',
      description: "มะม่วงที่ว่าหวาน ยังไม่สู้รอยยิ้มของเธอที่หวานกว่า",
      potassium: 141,
    },
    {
      name: 'มะละกอ (สุก)',
      image: 'https://today-obs.line-scdn.net/0hlxq0_6XgM1dMSRhwWppMAHYfMDh_JSBUKH9iVBAnbWM0fHUBIyZ5Zm4dZTJlKnQJIn98NGBIKGYxfyYEcyl5/w644',
      description: "ชื่อมะละกอ แต่เพื่อนๆ เรียกว่า 'ปาปายา'",
      potassium: 222,
    },
    {
      name: 'มังคุด',
      image: 'https://i0.wp.com/images-prod.healthline.com/hlcmsresource/images/AN_images/mangosteen-1296x728-feature.jpg?w=1155&h=1528',
      description: "เราก็เหมือนเธอเลยนะ... แม้ภายนอกจะดูแข็งแรง แต่ข้างในนุ่มนวลและหวานสุดๆ",
      potassium: 48,
    },
    {
      name: 'ลองกอง',
      image: 'https://res.cloudinary.com/dk0z4ums3/image/upload/v1523702656/attached_image_th/%E0%B8%A5%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%AD%E0%B8%87-%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%82%E0%B8%A2%E0%B8%8A%E0%B8%99%E0%B9%8C%E0%B9%80%E0%B8%9E%E0%B8%B7%E0%B9%88%E0%B8%AD-pobpad.jpg',
      description: "ผมมีเปลือกบางเบา แกะง่าย เข้าถึงตัวตนได้ไม่ยาก แต่ระวังยางนิดนึง",
      potassium: 182,
    },
    {
      name: 'ลิ้นจี่',
      image: 'https://www.disthai.com/images/editor/ลิ้นจี่2.jpg',
      description: "รูปร่างกลมป้อม น่ากอด หวานฉ่ำชวนลิ้มลอง 😋",
      potassium: 214,
    },
    {
      name: 'ลำไย',
      image: 'https://s359.kapook.com/pagebuilder/a3668391-961b-4b33-911c-4b7229cce1cc.jpg',
      description: "ถ้าผมเป็นลำไย ก็อยากให้คุณเป็นเมล็ด เพราะผมอยากจะอยู่ใกล้คุณทุกวัน ❤️",
      potassium: 244,
    },
    {
      name: 'สตรอเบอร์รี่',
      image: 'https://kaset.today/wp-content/uploads/2021/03/2-98.jpg',
      description: "อยากชวนคุณไปทำสตรอว์เบอร์รี่ปั่นด้วยกันจัง เพราะจะปั่นหัวคุณให้คิดถึงผมตลอดเวลา",
      potassium: 145,
    },
    {
      name: 'ส้มโอ',
      image: 'https://www.gourmetandcuisine.com/Images/editor_upload/_editor20210831021126_original.jpg',
      description: "ส้มโออาจจะไม่หวานเท่าไหร่ แต่ถ้าลองรักกับเราเมื่อไหร่ หวานใจแน่นอน! 😘",
      potassium: 154,
    },
    {
      name: 'สับปะรด',
      image: 'https://www.thaihealth.or.th/data/content/2018/06/43197/cms/newscms_thaihealth_c_abeituwyz369.jpg',
      description: "ฉันน่ะสับปะรด แต่เธอน่ะสับใจฉันจนละเอียด",
      potassium: 112,
    },
    {
      name: 'องุ่นแดง',
      image: 'https://static.thairath.co.th/media/dFQROr7oWzulq5Fa5ya4fxNlv5uf8JdsoObg3L0lIkrYikrE5MlMCsdEtJSP2b6yzjZ.jpg',
      description: "มักอยู่เป็นกลุ่มกับเพื่อนๆ ดูภายนอกอาจจะตัวเล็ก แต่อัดแน่นไปด้วยคุณภาพ",
      potassium: 174,
    },
    {
      name: 'แอปเปิ้ล',
      image: 'https://w0.peakpx.com/wallpaper/160/130/HD-wallpaper-apples-fruit-ripe-red-apples-fresh-fruit-apple.jpg',
      description: "อยากให้คุณคือสโนไวท์ เพราะผมน่ะชอบคุณงับบบ 😋",
      potassium: 115,
    },
  ];
  const [ items, setItems ] = useState([]);

  //page begins at 0 to refer fruits to calculate score
  const [ page, setPage ] = useState(1);
  const [ current_index, setCurrent_index ] = useState(items.length - 1);
  const [ show_result, setShow_result ] = useState(false);
  const [ record, setRecord ] = useState([]);
  const [ showRecord, setShowRecord ] = useState(false);

  useEffect(() => {
    let result = shuffleArray([...default_items]);
    setItems(result);
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  const onSwipe = (direction, fruit) => {
    const potassium = parseFloat(fruit.potassium);
    const score_to_add = ((potassium > 200 && direction === 'left') || (potassium <= 200 && (direction === 'right' || direction === 'up'))) ? 1 : 0;
    setPage(page + 1);

    let new_record = [...record];
    let obj = {...fruit};
    obj.score = score_to_add;
    new_record.push(obj);
    setRecord(new_record);

    showResult(score_to_add === 1);
  }

  function showResult(answer_correct) {
    setShow_result(true);

    if(answer_correct) {
      ani_control_correct.start("visible");
    }
    else {
      ani_control_incorrect.start("visible");
    }

    setTimeout(() => {
      ani_control_correct.start("hidden");
      ani_control_incorrect.start("hidden");
    }, 500);

    setTimeout(() => {
      setShow_result(false);
    }, 800);
  }

  const ref = useRef([]);

  const ani_control_correct = useAnimationControls();
  const ani_control_incorrect = useAnimationControls();
  const ani_obj = {
    hidden: {
      opacity: 1,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5 }
    }
  }

  useEffect(() => {
    ani_control_correct.start("hidden");
    ani_control_incorrect.start("hidden");
  }, []);

  return (
    <div className='w-screen h-screen flex flex-col'>
      <Header/>

      <div className='grow flex justify-center'>
        <div className={'relative w-96 h-full justify-center items-center mx-2 md:mx-6 ' + ((items.length > 0) ? 'flex' : 'hidden')}>
          {
            items.map((x, i) => {
              return (
                <TinderCard key={i} preventSwipe={['down']} className='absolute w-full h-full 2xl:h-5/6' ref={(element) => ref.current[i] = element}
                onSwipe={(direction) => {
                  onSwipe(direction, x);
                  setCurrent_index(current_index - 1);
                }}>
                  <div className='relative w-full h-full bg-center rounded-lg cursor-pointer' style={{backgroundImage: "url(" + x.image + ")", backgroundSize: "cover"}}>
                    <div className='absolute bottom-0 left-0 w-full h-fit py-6 rounded-b-lg bg-gradient-to-t from-black'>
                      <p className='notosan-semi text-2xl text-white z-10 mx-6'>{x.name}</p>
                      <p className='notosan-reg text-sm text-white z-10 mx-6 mt-2'>{x.description}</p>
                    </div>
                  </div>
                </TinderCard>
              )
            })
          }

          <div className={'absolute top-1/2 left-1/2 w-28 h-28 bg-transparent justify-center items-center -translate-x-1/2 -translate-y-1/2 ' + (show_result ? 'flex' : 'hidden')}>
            <motion.div 
            variants={ani_obj}
            initial={"hidden"}
            animate={ani_control_correct}
            className='w-28 h-28 bg-white rounded-full flex justify-center items-center shadow-md'>
              <span class="notosan-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-cyan-300">MATCH!!</span>
            </motion.div>
          </div>

          <div className={'absolute top-1/2 left-1/2 w-28 h-28 bg-transparent justify-center items-center -translate-x-1/2 -translate-y-1/2 ' + (show_result ? 'flex' : 'hidden')}>
            <motion.div 
            variants={ani_obj}
            initial={"hidden"}
            animate={ani_control_incorrect}
            className='w-28 h-28 bg-white rounded-full flex justify-center items-center shadow-md'>
              <span class="notosan-bold text-lg bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-red-500">UNMATCH!!</span>
            </motion.div>
          </div>
        </div>
      </div>

      <Footer score={page} maxScore={items.length}
      handleLike={() => {
        ref.current[current_index].swipe('right');
        setCurrent_index(current_index - 1);
      }}
      handleDislike={() => {
        ref.current[current_index].swipe('left');
        setCurrent_index(current_index - 1);
      }}
      handleSuperlike={() => {
        ref.current[current_index].swipe('up');
        setCurrent_index(current_index - 1);
      }}
      handle_look_score={() => setShowRecord(true)}/>

      <Modal_show_record isShow={showRecord} records={record} maxScore={items.length} handle_close={() => setShowRecord(false)}/>
    </div>
  )
}

export default Potassium_Tinder