import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Potassium_Tinder from './pages/Potassium/Potassium_Tinder';
import Nutrition_Flag from './pages/Nutrition_Flag/Nutrition_Flag';

import 'boxicons';

const router = createBrowserRouter([
  {
    path: "/",
    element: 
      <h1 className="text-3xl font-bold underline">
        Hello world!
      </h1>,
  },
  {
    path: "/flag",
    element: <Nutrition_Flag/>,
  },
  {
    path: "/potassium",
    element: <Potassium_Tinder/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
